var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "year-selector" }, [
    _c("div", { staticClass: "year-display" }, [
      _vm._v(_vm._s(_vm.year_label)),
    ]),
    _c(
      "div",
      {
        staticClass: "nav",
        on: {
          click: function ($event) {
            return _vm.handleNavigate(-1)
          },
        },
      },
      [_c("i", { staticClass: "el-icon-caret-left" })]
    ),
    _c(
      "div",
      {
        staticClass: "nav",
        on: {
          click: function ($event) {
            return _vm.handleNavigate(1)
          },
        },
      },
      [_c("i", { staticClass: "el-icon-caret-right" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }