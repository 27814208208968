var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "project-month" }, [
    _c(
      "div",
      {
        ref: "project_month_value",
        staticClass: "value-display",
        class: [_vm.month_status, { show_invoices: _vm.show_invoices }],
        on: { click: _vm.toggleShowInvoices },
      },
      [
        _c("i", { class: _vm.icon }),
        _c("span", { staticClass: "value" }, [
          _vm._v(_vm._s(_vm._f("currency")(_vm.value.total))),
        ]),
      ]
    ),
    _vm.show_invoices
      ? _c(
          "div",
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: (event) => _vm.toggleShowInvoices(event, false),
                expression: "(event) => toggleShowInvoices(event, false)",
              },
            ],
            staticClass: "invoice-list",
            class: _vm.popup_class,
          },
          _vm._l(_vm.value.invoices, function (invoice) {
            return _c(
              "div",
              {
                key: invoice.invoice_number,
                staticClass: "invoice-row",
                on: {
                  click: function ($event) {
                    return _vm.handleClickInvoice(invoice, true)
                  },
                },
              },
              [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "value-display invoice-number",
                      class: invoice.status,
                    },
                    [
                      _c("i", {
                        staticClass: "icon",
                        class: _vm.getIconForStatus(invoice.status),
                      }),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(
                          " " +
                            _vm._s(invoice.invoice_number ?? "Pending") +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _c("span", { staticClass: "reference" }, [
                    invoice.status === "scheduled" ||
                    invoice.status === "unscheduled"
                      ? _c("b", [
                          _vm._v(_vm._s(_vm.fireRef2id(invoice.estimate))),
                        ])
                      : _c("b", [_vm._v(_vm._s(invoice.reference))]),
                  ]),
                ]),
                _c("span", { staticClass: "amount" }, [
                  _vm._v(_vm._s(_vm._f("currency")(invoice.amount))),
                ]),
                _c("span", { staticClass: "gst" }, [_vm._v("(inc. GST)")]),
                _c("i", { staticClass: "arrow el-icon-arrow-right" }),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }